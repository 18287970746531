html,
body,
#root {
  width: 100%;
  margin: 0;
  padding: 0;
}

.repo-node:hover {
  background-color: rgb(240, 240, 240);
}
